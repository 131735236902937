import type { Pinia } from 'pinia';

import type { Guideline } from '@grasp-gg/lib-core-guidelines';

import {} from '@tanstack/vue-query';
import { useAuthStore } from '../../../store/auth';
import listGuidelines from '../../api/guidelines/list-guidelines';

export default async (
  pinia?: Pinia,
  cache = true,
): Promise<Guideline[] | undefined> => {
  const authStore = useAuthStore(pinia);
  const organizationId = authStore.user?.organizationId;

  if (!organizationId) {
    throw new CustomFunctions.Error(
      CustomFunctions.ErrorCode.notAvailable,
      'Missing Organization ID',
    );
  }

  const expire = Math.floor(Date.now() / (1000 * 60));

  const cacheId = `${organizationId}_guidelines_${expire}`;

  const guidelinesFromCache =
    (await OfficeRuntime.storage.getItem(cacheId)) ?? undefined;

  if (guidelinesFromCache && cache) {
    return JSON.parse(guidelinesFromCache);
  }

  const guidelineResult = await listGuidelines(organizationId, true, pinia);

  if (!guidelineResult?.success) {
    throw new CustomFunctions.Error(
      CustomFunctions.ErrorCode.notAvailable,
      'Fail to fetch guideline',
    );
  }

  await OfficeRuntime.storage.setItem(
    cacheId,
    JSON.stringify(guidelineResult.guidelines),
  );

  return guidelineResult.guidelines;
};
