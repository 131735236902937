export default [
  {
    key: 'africa',
    name: 'Africa',
    type: 'country_group',
    country_codes: [
      'BI',
      'KM',
      'DJ',
      'ER',
      'ET',
      'KE',
      'MG',
      'MW',
      'MU',
      'YT',
      'MZ',
      'RE',
      'RW',
      'SC',
      'SO',
      'SS',
      'UG',
      'TZ',
      'ZM',
      'ZW',
      'AO',
      'CM',
      'CF',
      'TD',
      'CG',
      'CD',
      'GQ',
      'GA',
      'ST',
      'DZ',
      'EG',
      'LY',
      'MA',
      'TN',
      'EH',
      'BW',
      'LS',
      'NA',
      'ZA',
      'SZ',
      'BJ',
      'BF',
      'CV',
      'GM',
      'GH',
      'GN',
      'GW',
      'LR',
      'ML',
      'MR',
      'NE',
      'NG',
      'SH',
      'SN',
      'SL',
      'TG',
      'CI',
    ],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'afta',
    name: 'ASEAN Free Trade Area',
    type: 'country_group',
    country_codes: ['BN', 'ID', 'MY', 'PH', 'SG', 'TH', 'VN', 'LA', 'MM', 'KH'],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'android_free_store',
    name: 'Android app countries (all)',
    type: 'country_group',
    country_codes: [
      'AF',
      'AL',
      'DZ',
      'AS',
      'AD',
      'AO',
      'AI',
      'AQ',
      'AG',
      'AR',
      'AM',
      'AW',
      'AU',
      'AT',
      'AZ',
      'BH',
      'BD',
      'BB',
      'BY',
      'BE',
      'BZ',
      'BJ',
      'BM',
      'BT',
      'BO',
      'BA',
      'BW',
      'BR',
      'IO',
      'VG',
      'BN',
      'BG',
      'BF',
      'BI',
      'KH',
      'CM',
      'MZ',
      'MM',
      'NA',
      'NR',
      'NP',
      'NL',
      'FJ',
      'NC',
      'NZ',
      'NI',
      'NE',
      'NG',
      'NU',
      'NF',
      'FI',
      'NO',
      'OM',
      'PK',
      'PW',
      'PS',
      'PA',
      'PG',
      'PY',
      'PE',
      'PH',
      'PN',
      'PL',
      'PT',
      'PR',
      'QA',
      'RE',
      'RO',
      'RU',
      'RW',
      'KN',
      'LC',
      'MF',
      'PM',
      'VC',
      'WS',
      'SM',
      'ST',
      'SA',
      'SN',
      'RS',
      'SC',
      'SL',
      'SG',
      'SX',
      'SK',
      'SI',
      'SB',
      'SO',
      'ZA',
      'GS',
      'KR',
      'SS',
      'ES',
      'LK',
      'BL',
      'SH',
      'SR',
      'SJ',
      'SZ',
      'SE',
      'CH',
      'TW',
      'TJ',
      'TZ',
      'TH',
      'BS',
      'TG',
      'TK',
      'TO',
      'TT',
      'TN',
      'TR',
      'TM',
      'TC',
      'TV',
      'UG',
      'UA',
      'AE',
      'GB',
      'UM',
      'US',
      'VI',
      'UY',
      'UZ',
      'VU',
      'VA',
      'VE',
      'VN',
      'WF',
      'EH',
      'YE',
      'ZM',
      'ZW',
      'FR',
      'GF',
      'PF',
      'TF',
      'GA',
      'GM',
      'GE',
      'DE',
      'GH',
      'GI',
      'GR',
      'GL',
      'GD',
      'GP',
      'GU',
      'GT',
      'GG',
      'GN',
      'GW',
      'GY',
      'HT',
      'HN',
      'HK',
      'HU',
      'IS',
      'IN',
      'ID',
      'IQ',
      'IE',
      'IM',
      'IL',
      'IT',
      'CI',
      'JM',
      'JP',
      'JE',
      'JO',
      'KZ',
      'KE',
      'KI',
      'XK',
      'KW',
      'KG',
      'LA',
      'LV',
      'LB',
      'LS',
      'LR',
      'LY',
      'LI',
      'LT',
      'LU',
      'MO',
      'MK',
      'MG',
      'MW',
      'MY',
      'MV',
      'ML',
      'MT',
      'MP',
      'MH',
      'MQ',
      'MR',
      'MU',
      'YT',
      'MX',
      'MD',
      'MC',
      'MN',
      'ME',
      'MS',
      'MA',
      'CA',
      'CV',
      'KY',
      'CF',
      'TD',
      'CL',
      'CN',
      'CX',
      'CC',
      'CO',
      'KM',
      'CG',
      'CD',
      'CK',
      'CR',
      'HR',
      'CW',
      'CY',
      'CZ',
      'DK',
      'DJ',
      'DM',
      'DO',
      'TL',
      'EC',
      'EG',
      'SV',
      'GQ',
      'ER',
      'EE',
      'ET',
      'FK',
      'FO',
      'FM',
    ],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'android_paid_store',
    name: 'Android app countries (paid)',
    type: 'country_group',
    country_codes: [
      'AL',
      'DZ',
      'AO',
      'AG',
      'AR',
      'AM',
      'AW',
      'AU',
      'AT',
      'AZ',
      'BH',
      'BD',
      'BY',
      'BE',
      'BZ',
      'BJ',
      'BO',
      'BA',
      'BW',
      'BR',
      'BG',
      'BF',
      'KH',
      'CM',
      'CA',
      'CV',
      'CL',
      'CN',
      'CO',
      'CR',
      'HR',
      'CY',
      'CZ',
      'DK',
      'DO',
      'EC',
      'MK',
      'MY',
      'ML',
      'MT',
      'MU',
      'MX',
      'MD',
      'MA',
      'MZ',
      'NA',
      'NP',
      'NL',
      'LU',
      'NZ',
      'NI',
      'NE',
      'NG',
      'NO',
      'OM',
      'PK',
      'PA',
      'PG',
      'PY',
      'PE',
      'PH',
      'PL',
      'PT',
      'PR',
      'QA',
      'RO',
      'RU',
      'RW',
      'SA',
      'SN',
      'RS',
      'SG',
      'SK',
      'SI',
      'ZA',
      'KR',
      'ES',
      'LK',
      'SE',
      'CH',
      'TW',
      'TJ',
      'TZ',
      'TH',
      'BS',
      'TG',
      'TT',
      'TN',
      'TR',
      'TM',
      'UG',
      'UA',
      'AE',
      'GB',
      'US',
      'UY',
      'UZ',
      'VE',
      'VN',
      'YE',
      'ZM',
      'ZW',
      'EG',
      'SV',
      'EE',
      'FJ',
      'FI',
      'FR',
      'GA',
      'DE',
      'GH',
      'GR',
      'GT',
      'GW',
      'HT',
      'HN',
      'HK',
      'HU',
      'IS',
      'IN',
      'ID',
      'IE',
      'IL',
      'IT',
      'CI',
      'JM',
      'JP',
      'JO',
      'KZ',
      'KE',
      'KW',
      'KG',
      'LA',
      'LV',
      'LB',
      'LI',
      'LT',
    ],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'apec',
    name: 'Asia-Pacific Economic Cooperation',
    type: 'country_group',
    country_codes: [
      'AU',
      'BN',
      'CA',
      'CL',
      'CN',
      'HK',
      'ID',
      'JP',
      'KR',
      'MY',
      'MX',
      'NZ',
      'PG',
      'PE',
      'PH',
      'RU',
      'SG',
      'TH',
      'US',
      'VN',
    ],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'asia',
    name: 'Asia',
    type: 'country_group',
    country_codes: [
      'KZ',
      'KG',
      'TJ',
      'TM',
      'UZ',
      'CN',
      'HK',
      'MO',
      'TW',
      'JP',
      'MN',
      'KR',
      'AF',
      'BD',
      'BT',
      'IN',
      'MV',
      'NP',
      'PK',
      'LK',
      'BN',
      'KH',
      'ID',
      'LA',
      'MY',
      'MM',
      'PH',
      'SG',
      'TH',
      'TL',
      'VN',
      'AM',
      'AZ',
      'BH',
      'CY',
      'GE',
      'IQ',
      'IL',
      'JO',
      'KW',
      'LB',
      'OM',
      'QA',
      'SA',
      'PS',
      'TR',
      'AE',
      'YE',
    ],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'caribbean',
    name: 'Caribbean',
    type: 'country_group',
    country_codes: [
      'AG',
      'AW',
      'BS',
      'BB',
      'VG',
      'KY',
      'CW',
      'DM',
      'DO',
      'GD',
      'GP',
      'HT',
      'JM',
      'MQ',
      'MS',
      'PR',
      'BL',
      'KN',
      'LC',
      'MF',
      'VC',
      'SX',
      'TT',
      'TC',
      'VI',
    ],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'central_america',
    name: 'Central America',
    type: 'country_group',
    country_codes: ['BZ', 'CR', 'SV', 'GT', 'HN', 'NI', 'PA'],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'cisfta',
    name: 'Commonwealth of Independent States Free Trade Area',
    type: 'country_group',
    country_codes: [
      'RU',
      'UA',
      'BY',
      'UZ',
      'MD',
      'AM',
      'KG',
      'KZ',
      'AZ',
      'GE',
      'TJ',
    ],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'eea',
    name: 'European Economic Area (EEA)',
    type: 'country_group',
    country_codes: [
      'AT',
      'BE',
      'BG',
      'HR',
      'CY',
      'CZ',
      'DK',
      'EE',
      'FI',
      'FR',
      'DE',
      'GR',
      'HU',
      'IE',
      'IT',
      'LV',
      'LT',
      'LU',
      'MT',
      'NL',
      'PL',
      'PT',
      'RO',
      'SK',
      'SI',
      'ES',
      'SE',
      'GB',
      'IS',
      'LI',
      'NO',
    ],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'emerging_markets',
    name: 'Emerging Markets',
    type: 'country_group',
    country_codes: [
      'EG',
      'KE',
      'NG',
      'ZA',
      'CN',
      'ID',
      'IN',
      'MY',
      'PH',
      'TH',
      'VN',
      'PL',
      'RU',
      'TR',
      'AR',
      'BR',
      'CL',
      'CO',
      'MX',
      'AE',
      'SA',
    ],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'europe',
    name: 'Europe',
    type: 'country_group',
    country_codes: [
      'BY',
      'BG',
      'CZ',
      'HU',
      'PL',
      'MD',
      'RO',
      'RU',
      'SK',
      'UA',
      'AX',
      'DK',
      'EE',
      'FO',
      'FI',
      'GG',
      'IS',
      'IE',
      'IM',
      'JE',
      'LV',
      'LT',
      'NO',
      'SJ',
      'SE',
      'GB',
      'AL',
      'AD',
      'BA',
      'HR',
      'GI',
      'GR',
      'VA',
      'IT',
      'MT',
      'ME',
      'PT',
      'SM',
      'RS',
      'SI',
      'ES',
      'MK',
      'AT',
      'BE',
      'FR',
      'DE',
      'LI',
      'LU',
      'MC',
      'NL',
      'CH',
      'XK',
    ],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'gcc',
    name: 'Gulf Cooperation Council (GCC)',
    type: 'country_group',
    country_codes: ['BH', 'KW', 'OM', 'QA', 'SA', 'AE'],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'itunes_app_store',
    name: 'iTunes App Store Countries',
    type: 'country_group',
    country_codes: [
      'AL',
      'DZ',
      'AO',
      'AI',
      'AG',
      'AR',
      'AM',
      'AU',
      'AT',
      'AZ',
      'BH',
      'BB',
      'BY',
      'BE',
      'BZ',
      'BJ',
      'BM',
      'BT',
      'BO',
      'BW',
      'BR',
      'VG',
      'BN',
      'BG',
      'BF',
      'KH',
      'CA',
      'CV',
      'KY',
      'TD',
      'CL',
      'CN',
      'CO',
      'CG',
      'CR',
      'HR',
      'CY',
      'CZ',
      'DK',
      'LU',
      'MO',
      'MK',
      'MG',
      'MW',
      'MY',
      'ML',
      'MT',
      'MR',
      'MU',
      'MX',
      'MD',
      'MN',
      'MS',
      'MZ',
      'NA',
      'NP',
      'NL',
      'NZ',
      'NI',
      'NE',
      'NG',
      'NO',
      'OM',
      'PK',
      'PW',
      'PA',
      'PG',
      'PY',
      'PE',
      'PH',
      'PL',
      'PT',
      'QA',
      'RO',
      'RU',
      'KN',
      'LC',
      'VC',
      'ST',
      'SA',
      'SN',
      'SC',
      'SL',
      'SG',
      'SK',
      'SI',
      'SB',
      'ZA',
      'KR',
      'ES',
      'LK',
      'SR',
      'SZ',
      'SE',
      'CH',
      'TW',
      'TJ',
      'TZ',
      'TH',
      'BS',
      'TT',
      'TN',
      'TR',
      'TM',
      'TC',
      'UG',
      'UA',
      'AE',
      'GB',
      'US',
      'UY',
      'UZ',
      'VE',
      'VN',
      'YE',
      'ZW',
      'DM',
      'DO',
      'EC',
      'EG',
      'SV',
      'EE',
      'FM',
      'FJ',
      'FI',
      'FR',
      'GM',
      'DE',
      'GH',
      'GR',
      'GD',
      'GT',
      'GW',
      'GY',
      'HN',
      'HK',
      'HU',
      'IS',
      'IN',
      'ID',
      'IE',
      'IL',
      'IT',
      'JM',
      'JP',
      'JO',
      'KZ',
      'KE',
      'KW',
      'KG',
      'LA',
      'LV',
      'LB',
      'LR',
      'LT',
    ],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'mercosur',
    name: 'Mercosur',
    type: 'country_group',
    country_codes: ['BR', 'AR', 'UY', 'PY', 'VE'],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'nafta',
    name: 'North American Free Trade Agreement',
    type: 'country_group',
    country_codes: ['US', 'CA', 'MX'],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'north_america',
    name: 'North America',
    type: 'country_group',
    country_codes: ['BM', 'CA', 'GL', 'MX', 'PM', 'US'],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'oceania',
    name: 'Oceania',
    type: 'country_group',
    country_codes: [
      'AU',
      'NZ',
      'NF',
      'FJ',
      'NC',
      'PG',
      'SB',
      'VU',
      'GU',
      'KI',
      'MH',
      'FM',
      'NR',
      'MP',
      'PW',
      'AS',
      'CK',
      'PF',
      'NU',
      'PN',
      'WS',
      'TK',
      'TO',
      'TV',
      'WF',
    ],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'south_america',
    name: 'South America',
    type: 'country_group',
    country_codes: [
      'AR',
      'BO',
      'BR',
      'CL',
      'CO',
      'EC',
      'FK',
      'GF',
      'GY',
      'PY',
      'PE',
      'SR',
      'UY',
      'VE',
    ],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'worldwide',
    name: 'Worldwide',
    type: 'country_group',
    country_codes: [
      'AD',
      'AE',
      'AF',
      'AG',
      'AI',
      'AL',
      'AM',
      'AO',
      'AQ',
      'AR',
      'AS',
      'AT',
      'AU',
      'AW',
      'AX',
      'AZ',
      'BA',
      'BB',
      'BD',
      'BE',
      'BF',
      'BG',
      'BH',
      'BI',
      'BJ',
      'BL',
      'BM',
      'BN',
      'BO',
      'BQ',
      'BR',
      'BS',
      'BT',
      'BW',
      'BY',
      'BZ',
      'CA',
      'CC',
      'CD',
      'CF',
      'CG',
      'CH',
      'CI',
      'CK',
      'CL',
      'CM',
      'CN',
      'CO',
      'CR',
      'CV',
      'CW',
      'CX',
      'CY',
      'CZ',
      'DE',
      'DJ',
      'DK',
      'DM',
      'DO',
      'DZ',
      'EC',
      'EE',
      'EG',
      'EH',
      'ER',
      'ES',
      'ET',
      'MD',
      'ME',
      'MF',
      'MG',
      'MH',
      'MK',
      'ML',
      'MM',
      'MN',
      'MO',
      'MP',
      'MQ',
      'MR',
      'MS',
      'MT',
      'MU',
      'MV',
      'MW',
      'MX',
      'MY',
      'MZ',
      'NA',
      'NC',
      'NE',
      'NF',
      'NG',
      'NI',
      'NL',
      'NO',
      'NP',
      'NR',
      'NU',
      'NZ',
      'OM',
      'PA',
      'PE',
      'PF',
      'PG',
      'PH',
      'PK',
      'PL',
      'PM',
      'PN',
      'PR',
      'PS',
      'PT',
      'PW',
      'PY',
      'QA',
      'RE',
      'RO',
      'RS',
      'RU',
      'RW',
      'SA',
      'SB',
      'SC',
      'SE',
      'SG',
      'SH',
      'SI',
      'SJ',
      'SK',
      'SL',
      'SM',
      'SN',
      'SO',
      'SR',
      'SS',
      'ST',
      'SV',
      'SX',
      'SZ',
      'TC',
      'TD',
      'TF',
      'TG',
      'TH',
      'TJ',
      'TK',
      'TL',
      'TM',
      'TN',
      'TO',
      'TR',
      'TT',
      'TV',
      'TW',
      'TZ',
      'UA',
      'UG',
      'UM',
      'US',
      'UY',
      'UZ',
      'VA',
      'VC',
      'VE',
      'VG',
      'VI',
      'VN',
      'VU',
      'WF',
      'WS',
      'XK',
      'YE',
      'YT',
      'ZA',
      'ZM',
      'ZW',
      'MC',
      'FI',
      'FJ',
      'FK',
      'FM',
      'FO',
      'FR',
      'GA',
      'GB',
      'GD',
      'GE',
      'GF',
      'GG',
      'GH',
      'GI',
      'GL',
      'GM',
      'GN',
      'GP',
      'GQ',
      'GR',
      'GS',
      'GT',
      'GU',
      'GW',
      'GY',
      'HK',
      'HN',
      'HR',
      'HT',
      'HU',
      'ID',
      'IE',
      'IL',
      'IM',
      'IN',
      'IO',
      'IQ',
      'IS',
      'IT',
      'JE',
      'JM',
      'JO',
      'JP',
      'KE',
      'KG',
      'KH',
      'KI',
      'KM',
      'KN',
      'MA',
      'KR',
      'KW',
      'KY',
      'KZ',
      'LA',
      'LB',
      'LC',
      'LI',
      'LK',
      'LR',
      'LS',
      'LT',
      'LU',
      'LV',
      'LY',
    ],
    is_worldwide: true,
    supports_region: true,
    supports_city: true,
  },
  {
    key: 'euro_area',
    name: 'Euro Area',
    type: 'country_group',
    country_codes: [
      'AT',
      'BE',
      'CY',
      'DE',
      'EE',
      'ES',
      'FI',
      'FR',
      'GR',
      'IE',
      'IT',
      'LT',
      'LU',
      'LV',
      'MT',
      'NL',
      'PT',
      'SI',
      'SK',
    ],
    is_worldwide: false,
    supports_region: true,
    supports_city: true,
  },
];
