export const single =
  <TInput, TOutput>(batch: (inputs: TInput[]) => Promise<TOutput[]>) =>
  (input: TInput) =>
    batch([input]).then(([output]) => output);

export const nonNullish = <T>(value: T): value is NonNullable<T> =>
  value !== undefined && value !== null;
export const nullish = (value: unknown): value is null | undefined =>
  value === undefined || value === null;

export const nullable = <T>(
  value: T | null | undefined,
  defaultValue: T | null,
) => (value === undefined ? defaultValue : value);

export const onDefined = <T, U>(value: T | undefined, fn: (value: T) => U) =>
  value === undefined ? undefined : fn(value);
