import type { TemplateGuidelineApiModel } from '@grasp-gg/lib-core-guidelines';
import {
  validateRegex,
  validateTaxonomyTemplate,
} from '@grasp-gg/lib-core-taxonomies';

function validateTaxonomy(
  value: string[][],
  guideline: TemplateGuidelineApiModel,
): boolean[][] {
  const validators = guideline.template
    ? [
        (text: string) =>
          validateTaxonomyTemplate(guideline.template, text, {
            instantFail: true,
          }),
        ...(guideline.alternativeTemplates || []).map(
          (template) => (text: string) =>
            validateTaxonomyTemplate(template.value?.template, text, {
              instantFail: true,
            }),
        ),
      ]
    : [
        (text: string) =>
          validateRegex(guideline.fields || [], text, {
            instantFail: true,
          }),
      ];

  return value.map((col) =>
    col.map((row) =>
      validators.some((validator) => !validator(row).invalidFields.length),
    ),
  );
}

export default validateTaxonomy;
