import escapeRegExp from 'lodash/escapeRegExp';

import type { StandardFieldAnything } from '../../fields';

export const containsExcludedWords = (
  field: StandardFieldAnything,
  value?: string,
) => {
  if (!field.exclude || !value) {
    return false;
  }

  const regex = /"([^"]*)"/g;
  let matches;
  const excludedWords = [];

  while ((matches = regex.exec(field.exclude)) !== null) {
    excludedWords.push(matches[1]);
  }

  return excludedWords.some((word) => value.includes(word));
};

export default (field: StandardFieldAnything): string => {
  if (field.exclude) {
    // we don't check excluded words ("xxx") in the regex
    const regex = /"([^"]*)"/g;

    const excludedCharacters = field.exclude
      .replaceAll(regex, '')
      .replace('-', '\\-');

    if (excludedCharacters) {
      return `[^${escapeRegExp(excludedCharacters).replace('-', '\\-')}]`;
    }
  }

  return '.';
};
