import superjson from 'superjson';

const NS = import.meta.env.VITE_FUNCTIONS_NAMESPACE;

export interface TemplateGeneration {
  templateId: string;
  encodedValues: string;
  decodeValues: Record<string, unknown>;
}

export function isTaxoTemplateGeneration(
  // A formula is always typed as a string in Excel
  // but it appears that the value can be a number depending of the cell value
  // so we consider it as unknown to avoid issues
  formula?: unknown,
): TemplateGeneration | null {
  if (typeof formula !== 'string') return null;

  const sourceRegex = new RegExp(
    `=${NS}.TAXO_TEMPLATE\\("(?<templateId>.*)","(?<encodedValues>.*)".*\\)`,
  );
  const result = formula.match(sourceRegex);
  const templateId = result?.groups?.templateId ?? null;
  const encodedValues = result?.groups?.encodedValues ?? '';

  if (templateId)
    return {
      templateId,
      encodedValues,
      decodeValues: decodeEncodedValues(encodedValues),
    };

  return null;
}

export function asTaxoTemplateGeneration(
  templateId: string,
  values: Record<string, unknown>,
  precedents: string[],
) {
  return `=${NS}.TAXO_TEMPLATE("${templateId}","${encodeEncodedValues(values)}",${precedents.join(',')})`;
}

export function decodeEncodedValues(
  encodedValues: string,
): Record<string, unknown> {
  try {
    return superjson.parse(atob(encodedValues));
  } catch {
    return {};
  }
}

function encodeEncodedValues(encodedValues: Record<string, unknown>) {
  try {
    return btoa(superjson.stringify(encodedValues));
  } catch {
    return {};
  }
}
