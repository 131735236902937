import type { TaxonomyFieldUrl } from '@grasp-gg/lib-core-taxonomies';

import { self } from './utils';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function asDataValidation(_field: TaxonomyFieldUrl) {
  // FIXME: by default excel does not support for regex validation
  // we implement a basic validation by checking if the value
  // starts with http:// or https://

  return {
    custom: {
      formula: `=OR(
        LEFT(${self()},7)="http://",
        LEFT(${self()},8)="https://",
      )`,
    },
  } satisfies Excel.DataValidationRule;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function encodeParameters(_field: TaxonomyFieldUrl) {
  // FIXME: we are not using the field parameters
  // They seems to be useless for the URL field
  return [];
}
