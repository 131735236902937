import type { Pinia } from 'pinia';

import type { ListGuidelinesResponse } from '@grasp-gg/api-guidelines';

import { useApiStore } from '../../../store/api';

export default (
  organizationId: string,
  addTemplates: boolean,
  pinia?: Pinia,
) => {
  const apiStore = useApiStore(pinia);

  return apiStore.requestApiGuidelines<ListGuidelinesResponse<'facebook'>>(
    `/organizations/${organizationId}/guidelines?addTemplates=${addTemplates}`,
  );
};
