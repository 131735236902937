/* eslint-disable @typescript-eslint/no-use-before-define */
import type { FieldInclude } from './base';

export type FieldTextValue = string;

export type FieldTextValueInput = string | FieldTextValue;

/**
 * Convert field text value input to the value
 * that should be puts in the taxonomy template output.
 */
export function formatFieldTextValue(options: {
  /** Accepts `TaxonomyFieldText` */
  field: { type: 'text'; parameters: { include?: Partial<FieldInclude> } };
  input: FieldTextValueInput | undefined;
}): string {
  const { field, input } = options;

  return asTextValue(input, field.parameters.include ?? {}) ?? '';
}

/**
 * Convert field text value input to the value
 * structured output object.
 */
export function decodeFieldTextValue(options: {
  /** Accepts `TaxonomyFieldText` */
  field: { type: 'text'; parameters: { include?: Partial<FieldInclude> } };
  input: FieldTextValueInput | undefined;
}): FieldTextValue | undefined {
  const { field, input } = options;

  return asTextValue(input, field.parameters.include ?? {});
}

function asTextValue(
  value: FieldTextValueInput | undefined,
  includes: Partial<FieldInclude>,
) {
  if (value === undefined) return undefined;

  if (includes.uppercase !== false && includes.lowercase === false) {
    return value.toUpperCase();
  } else if (includes.uppercase === false && includes.lowercase !== false) {
    return value.toLowerCase();
  }

  return value;
}
