import {
  type MaxBudgetApiModel,
  type MaxDailyBudgetApiModel,
  checkValidityMaxBudget,
} from '@grasp-gg/lib-core-guidelines';

function validateMaxBudget(
  values: number[][],
  guideline: MaxBudgetApiModel | MaxDailyBudgetApiModel,
): boolean[][] {
  return values.map((col) =>
    col.map((row: number) => checkValidityMaxBudget(row, guideline.amount)),
  );
}

export default validateMaxBudget;
