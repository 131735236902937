import {
  type StandardField,
  type StandardFieldText,
  convertTaxonomyToStandard,
} from '../../fields';
import getByFieldOptions from './by-field-options';
import getByFieldType from './by-field-type';

const wrapOptional = (regex: string, required: boolean): string => {
  if (required) {
    return regex;
  }

  return `(?:${regex})?`;
};

// eslint-disable-next-line complexity
export default (
  field: StandardField,
  nextField: StandardField | null,
  captureGroup = false,
): string => {
  let regExField;

  const getSubRegex = (current: StandardField, next: StandardField | null) => {
    let c = `(?:${getByFieldType(current)})${getByFieldOptions(current, next)}`;

    if (
      current.type === 'choice' &&
      current.freeValue &&
      !current.saveFreeValue
    ) {
      const freeField: StandardFieldText = {
        type: 'text',
        label: current.label,
        required: current.required,
        min: current.min || 1,
        max: current.max || 250,
        include: current.include,
      };

      c = `(?:${c}|(?:(?:${getByFieldType(freeField)})${getByFieldOptions(freeField, next)}))`;
    }

    return c;
  };

  if (field.type === 'template' && field.template?.fields?.length) {
    const childrenFields = field.template.fields.map(convertTaxonomyToStandard);

    regExField = childrenFields
      .map((f, index) => getSubRegex(f, childrenFields[index + 1]))
      .join('');
  } else if (field.type === 'template' && field.defaultValue) {
    regExField = field.defaultValue;
  } else {
    regExField = getSubRegex(field, nextField);
  }

  if (field.prefix || field.suffix) {
    regExField = `(?:${field.prefix || ''}(?:${regExField})${field.suffix || ''})`;
  }

  if (field.type !== 'fixed' && field.defaultValue) {
    regExField = `(?:${field.defaultValue}|${regExField})`;
  }

  return `(${captureGroup ? '' : '?:'}${wrapOptional(regExField, field.required)})`;
};
