import escapeRegExp from 'lodash/escapeRegExp';

import type { StandardField } from '../../../../fields';
import type { ValidRegex } from '../../types';

const getOptimizedRegex = (
  fields: StandardField[],
  index: number,
  newRegex: string,
  result: RegExpExecArray,
) => {
  return `(${[
    escapeRegExp(result.groups?.new ?? ''),
    ...[
      // we check back up until 2 fields
      1, 2,
    ]
      .filter(
        (i) =>
          fields[index - i] &&
          // if the previous field is optional
          !fields[index - i]?.required &&
          // then the current field could match
          result[index - i + 1].match(newRegex),
      )
      .map((i) => escapeRegExp(result[index - i + 1])),
  ].join('|')})`;
};

const getOptimizedUrlRegex = (_regex: string, result: RegExpExecArray) => {
  return result[0].includes('?')
    ? `(${result[0].split('?')[0]}[?](?:[-a-zA-Z0-9()@:%_+.~#?&/=]*))`
    : `(${result[0]})`;
};

/**
 * @returns [valid, currentRegexes, newRegex] | undefined
 **/
// eslint-disable-next-line complexity
export default (
  fields: StandardField[],
  text: string,
  field: StandardField,
  index: number,
  currentRegexes: ValidRegex[],
  allRegex: string,
  newRegex: string,
): undefined | [boolean, ValidRegex[], string] => {
  if (field.type === 'url') {
    const result = new RegExp(allRegex).exec(text);

    if (result) {
      return [true, currentRegexes, getOptimizedUrlRegex(newRegex, result)];
    }
  } else if (field.type === 'choice' && !field.freeValue) {
    const result = new RegExp(allRegex).exec(text);

    if (result) {
      return [
        true,
        currentRegexes,
        getOptimizedRegex(fields, index, newRegex, result),
      ];
    }
  } else {
    const valid = new RegExp(allRegex).test(text);

    if (valid || !fields[index - 1] || field.type !== 'fixed') {
      return [valid, currentRegexes, newRegex];
    }
  }

  return undefined;
};
