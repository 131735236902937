/* eslint-disable @typescript-eslint/naming-convention */

import allowedGuidelines from './services/allowed-guidelines';
import listGuidelinesWithCache from './services/functions/guidelines/list-guidelines-with-cache';
import processGuideline from './services/functions/guidelines/process-guideline';
import getFieldWithCache from './services/functions/taxonomies/get-field-with-cache';
import processTaxonomy from './services/functions/taxonomies/process-taxonomy';
import { taxoTemplate } from './services/taxo/functions';

/**
 * Apply a guideline
 * @helpurl https://graspgg.kb.help/
 * @customfunction GUIDELINE
 * @param {any[][]} values Value(s) to verify
 * @param {string} guidelineId Guideline id
 * @returns {Promise<boolean[][]>} Return true if the guideline is validated
 */
async function guideline(
  // biome-ignore lint/suspicious/noExplicitAny: import eslint
  values: any[][],
  guidelineId: string,
): Promise<boolean[][]> {
  console.info('GUIDELINE', values);

  return processGuideline(
    Array.isArray(values) ? values : [[values]],
    guidelineId,
  );
}

/**
 * Available guidelines
 * @helpurl https://graspgg.kb.help/
 * @customfunction AVAILABLE_GUIDELINES
 * @returns {Promise<string[][]>} Return a list of guidelines that you can use in excel.
 */
async function availableGuidelines(): Promise<[string, string, string][]> {
  console.info('AVAILABLE_GUIDELINES');

  const guidelines = await listGuidelinesWithCache();

  const allowed = allowedGuidelines(guidelines ?? []);

  return [
    ['Guideline ID', 'Guideline Name', 'Network'],
    ...allowed.map<[string, string, string]>(({ id, title, networks }) => [
      id,
      title,
      networks.join(', '),
    ]),
  ];
}

/**
 * Apply a taxonomy template
 * @helpurl https://graspgg.kb.help/
 * @customfunction TAXONOMY
 * @param {any[][]} values Value(s) to verify
 * @param {string} templateId Template id
 * @returns {Promise<boolean[][]>} Return true if the taxonmy is validated
 */
async function taxonomy(
  // biome-ignore lint/suspicious/noExplicitAny: import eslint
  values: any[][],
  templateId: string,
): Promise<boolean[][] | string[][]> {
  console.info('TAXONOMY', values);

  return processTaxonomy(
    Array.isArray(values) ? values : [[values]],
    templateId,
  );
}

/**
 * Get field
 * @helpurl https://graspgg.kb.help/
 * @customfunction FIELD
 * @param {string} fieldId Field id
 * @returns {Promise<string[][]>} Return a list of key/value
 */
async function field(fieldId: string): Promise<string[][]> {
  console.info('FIELD', fieldId);

  const fieldResult = await getFieldWithCache(fieldId);

  console.log('fieldResult', fieldResult);

  if (!fieldResult) {
    throw new CustomFunctions.Error(
      CustomFunctions.ErrorCode.invalidName,
      'Field not found',
    );
  }

  if (fieldResult.type !== 'choice') {
    throw new CustomFunctions.Error(
      CustomFunctions.ErrorCode.invalidName,
      'Wrong field type',
    );
  }

  const result = fieldResult.parameters.keyValues.map(({ key, value }) => [
    key,
    value,
  ]);

  console.log('result', result);

  return result;
}

/**
 * Select a value from taxo field
 * @helpurl https://graspgg.kb.help/
 * @customfunction TAXO_TEMPLATE
 * @param templateId Taxo template id to generate
 * @param values Base64 encoded default values
 * @param dependencies Dependency values
 * @requiresAddress
 * @returns Return a list of key/value
 */
async function TAXO_TEMPLATE(
  templateId: string,
  values: string,
  // biome-ignore lint/suspicious/noExplicitAny: import eslint
  dependencies: any[],
  invocation: CustomFunctions.Invocation,
) {
  // TODO: mixpanel event on each functions invocation
  console.info(
    'TAXO_TEMPLATE',
    invocation.address,
    templateId,
    values,
    dependencies,
    { invocation },
  );

  // dependencies parameter is here only to force excel to trigger the function
  // when the user change the value from a precedent cell.
  return withErrorLogs(() =>
    taxoTemplate({
      atAddress: invocation.address!,
      templateId,
      encodedValues: values,
    }),
  );
}

CustomFunctions.associate('GUIDELINE', guideline);
CustomFunctions.associate('AVAILABLE_GUIDELINES', availableGuidelines);
CustomFunctions.associate('FIELD', field);
CustomFunctions.associate('TAXONOMY', taxonomy);
CustomFunctions.associate('TAXO_TEMPLATE', TAXO_TEMPLATE);

async function withErrorLogs<T>(fn: () => Promise<T>) {
  try {
    return await fn();
  } catch (error) {
    console.error('Function error', error);

    throw error;
  }
}
