import type { Pinia } from 'pinia';

import type { GetGuidelineResponse } from '@grasp-gg/api-guidelines';

import { useApiStore } from '../../../store/api';

export default (
  organizationId: string,
  guidelineId: string,
  addTemplate: boolean,
  pinia?: Pinia,
) => {
  const apiStore = useApiStore(pinia);

  return apiStore.requestApiGuidelines<GetGuidelineResponse>(
    `/organizations/${
      organizationId
    }/guidelines/${guidelineId}?addTemplate=${addTemplate}`,
  );
};
