import type { StandardField } from '../../fields';

export default (
  field: StandardField,
): field is Extract<
  StandardField,
  { type: 'fixed' | 'choice' | 'checkbox' | 'date' | 'template' | 'url' }
> => {
  return (
    field.type === 'fixed' ||
    field.type === 'choice' ||
    field.type === 'checkbox' ||
    field.type === 'date' ||
    field.type === 'template' ||
    field.type === 'url'
  );
};
