import type { StandardFieldDate } from '../../fields';

export default (field: StandardFieldDate): string => {
  return field.format
    .replace(/YYYY/g, '[0-9]{4}')
    .replace(/YY/g, '[0-9]{2}')
    .replace(/MM/g, '(?:0[1-9]|1[0-2])')
    .replace(/M/g, '(?:[1-9]|1[0-2])')
    .replace(/DD/g, '(?:0[1-9]|[1-2][0-9]|3[0-1])')
    .replace(/D/g, '(?:[1-9]|[1-2][0-9]|3[0-1])');
};
