import type { Pinia } from 'pinia';

import type { GetFieldResponse } from '@grasp-gg/api-taxonomies';

import { useApiStore } from '../../../store/api';

export default (organizationId: string, fieldId: string, pinia?: Pinia) => {
  const apiStore = useApiStore(pinia);

  return apiStore.requestApiTaxonomies<GetFieldResponse>(
    `/organizations/${organizationId}/fields/${fieldId}`,
  );
};
