import { useQuery } from '@tanstack/vue-query';
import type { Pinia } from 'pinia';
import { mapValues } from 'radash';
import { type MaybeRef, computed, toValue } from 'vue';

import { fieldDbToApi, pivotDbToApi } from '@grasp-gg/lib-core-taxonomies';
import type { BuilderValues } from '@grasp-gg/lib-web-taxonomies';

import type {
  GetTemplateResponse,
  ListTemplatesWithExternalDependenciesSuccessResponse,
} from '@grasp-gg/api-taxonomies';

import type { TemplateFieldValue } from '@grasp-gg/extension-excel/services/taxo/template-generation/find-template-generation-values';

import { useApiStore } from '../../../store/api';
import { useAuthStore } from '../../../store/auth';
import listTemplates from './list-templates';

export interface UseTemplateOptions {
  templateId: MaybeRef<string | null>;
}

export function useTemplate(options: UseTemplateOptions) {
  const authStore = useAuthStore();

  return useQuery({
    queryKey: ['taxo', 'template', options.templateId],
    queryFn: async () => {
      return getTemplate(
        authStore.user!.organizationId,
        toValue(options.templateId)!,
      );
    },
    enabled: computed(
      () => !!authStore.user?.organizationId && !!toValue(options.templateId),
    ),
  });
}

export function useTaxoBuilder(options: {
  templateIds: MaybeRef<string[]>;
}) {
  const authStore = useAuthStore();

  const query = useQuery({
    queryKey: ['taxo', 'templates', options.templateIds],
    queryFn: () =>
      listTemplates(
        authStore.user!.organizationId,
        true,
        true,
        false,
        toValue(options.templateIds),
        true,
      ),
    select: (data) => {
      if (!data || !data.success || !data.templates.length) {
        return null;
      }

      return {
        fieldPivots: (<ListTemplatesWithExternalDependenciesSuccessResponse>(
          data
        )).templates.flatMap(({ template, externalDependencies }) => [
          ...pivotDbToApi(template.fields).map((pivot) => ({
            ...pivot,
            templateId: template.id,
          })),
          ...fieldDbToApi(externalDependencies.fields),
        ]),
      };
    },
    enabled: computed(() => toValue(options.templateIds).length > 0),
  });

  return query;
}

export async function getTemplate(
  organizationId: string,
  templateId: string,
  pinia?: Pinia,
) {
  const response = await fetchTemplate(organizationId, templateId, pinia);

  if (!response?.success) throw response?.error;

  return response ?? null;
}

export async function getTemplateFieldPivots(
  organizationId: string,
  templateId: string,
  pinia?: Pinia,
) {
  const response = await fetchTemplate(organizationId, templateId, pinia);

  if (!response?.success) return null;

  return {
    template: response.template,
    fieldPivots: [
      ...pivotDbToApi(response.template.fields).map((pivot) => ({
        ...pivot,
        templateId: response.template.id,
      })),
      ...fieldDbToApi(response.externalDependencies.fields),
    ],
  };
}

export function hasTaxoBuilderValues(
  values: Record<string, TemplateFieldValue>,
): BuilderValues {
  return mapValues(values, (value) => {
    if (value.type === 'formula_encoded') {
      return {
        value: value.value,
        inheritedFrom: 'form',
      };
    } else if (value.type === 'same_axis' || value.type === 'precedent') {
      return {
        value: value.value,
        inheritedFrom: 'parent',
      };
    }

    return;
  });
}

function fetchTemplate(
  organizationId: string,
  templateId: string,
  pinia?: Pinia,
) {
  return useApiStore(pinia).requestApiTaxonomies<GetTemplateResponse>(
    `/organizations/${
      organizationId
    }/templates/${templateId}?convertSubTemplates=true&convertChanges=true`,
  );
}
