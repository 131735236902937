import type { TemplateFromDataBase } from '@grasp-gg/lib-core-taxonomies';

import { nonNullish } from '@grasp-gg/extension-excel/utils';
import { toRange } from '@grasp-gg/extension-excel/utils/excel/address';
import type { ToCellsInput } from '@grasp-gg/extension-excel/utils/excel/range';

import { listTaxoFieldValidations } from '../field-validation/list-taxo-field-validations';
import type { TaxoFieldValidation } from '../field-validation/serialization';
import { decodeEncodedValues } from './serialization';

interface AnyTemplateFieldValue {
  type: string;
  address: string;
  value: string;
}

/** Template value extracted from the cell precedents */
export interface TemplatePrecedentValue extends AnyTemplateFieldValue {
  type: 'precedent';
}

/** Template value extracted from the formula parameter */
export interface TemplateFormulaEncodedValue extends AnyTemplateFieldValue {
  type: 'formula_encoded';
}

/** Template value extracted from the formula */
export interface TemplateSameAxisValue extends AnyTemplateFieldValue {
  type: 'same_axis';
  axis: 'row' | 'column';
}

export type TemplateFieldValue =
  | TemplatePrecedentValue
  | TemplateFormulaEncodedValue
  | TemplateSameAxisValue;

export type TemplateValues = Record<string, TemplateFieldValue>;

/**
 * From the selected cell, load all related taxo fields and their values.
 * This includes:
 * - same axis values: row & column
 * - precedent values: computed from the selection cell formula
 * - formula encoded values: computed from the selection cell formula second parameter
 */
export async function findTemplateGenerationValues(options: {
  atAddress: string;
  template: TemplateFromDataBase;
  formulaEncodedValues: string;
  useSameAxisValues?: boolean;
  usePrecedentValues?: boolean;
  useFormulaEncodedValues?: boolean;
}) {
  const use = (
    value: boolean | undefined,
    fn: () => Promise<TemplateValues> | TemplateValues,
  ) => ((value ?? true) ? fn() : {});

  const templateFieldIds = new Set(
    options.template.fields.map((field) => field.fieldId),
  );

  return {
    ...(await use(options.useSameAxisValues, () =>
      templateSameAxisValues(
        options.atAddress,
        templateFieldIds.has.bind(templateFieldIds),
      ),
    )),
    ...(await use(options.usePrecedentValues, () =>
      templatePrecedentsValues(options.atAddress),
    )),
    ...(await use(options.useFormulaEncodedValues, () =>
      templateFormulaEncodedValues(
        options.atAddress,
        options.formulaEncodedValues,
        templateFieldIds.has.bind(templateFieldIds),
      ),
    )),
  };
}

function templateFormulaEncodedValues(
  targetAddress: string,
  formulaEncodedValues: string,
  isTemplateField: (fieldId: string) => boolean,
) {
  const values = decodeEncodedValues(formulaEncodedValues);

  return Object.fromEntries(
    Object.entries(values)
      .filter(([fieldId]) => isTemplateField(fieldId))
      .map(
        ([fieldId, value]) =>
          [
            fieldId,
            {
              type: 'formula_encoded',
              address: targetAddress,
              value,
            },
          ] as [string, TemplateFormulaEncodedValue],
      ),
  );
}

async function templateSameAxisValues(
  targetAddress: string,
  isTemplateField: (fieldId: string) => boolean,
) {
  return Excel.run(async (context) => {
    const column = toRange(context, targetAddress).getEntireColumn();
    const row = toRange(context, targetAddress).getEntireRow();

    const columnValues = await buildTemplateValues({
      range: column,
      taxoFieldValidationToTemplateValue: (address, value, { fieldId }) => {
        if (!isTemplateField(fieldId)) return null;

        return <TemplateSameAxisValue>{
          type: 'same_axis',
          axis: 'column',
          address,
          value,
        };
      },
    });

    const rowValues = await buildTemplateValues({
      range: row,
      taxoFieldValidationToTemplateValue: (address, value, { fieldId }) => {
        if (!isTemplateField(fieldId)) return null;

        return <TemplateSameAxisValue>{
          type: 'same_axis',
          axis: 'row',
          address,
          value,
        };
      },
    });

    // We take the range with the most fields
    return Object.keys(columnValues).length > Object.keys(rowValues).length
      ? columnValues
      : rowValues;
  });
}

async function templatePrecedentsValues(targetAddress: string) {
  try {
    return await Excel.run(async (context) => {
      const precedents = toRange(context, targetAddress).getPrecedents();

      return buildTemplateValues({
        range: precedents,
        taxoFieldValidationToTemplateValue: (address, value) =>
          <TemplatePrecedentValue>{
            type: 'precedent',
            address,
            value,
          },
      });
    });
  } catch (error) {
    if (error instanceof OfficeExtension.Error) {
      // `getPrecedents` throws ItemNotFound error when there are no precedents
      if (error.code === 'ItemNotFound') return {};
    }

    throw error;
  }
}

async function buildTemplateValues<T extends TemplateFieldValue>(options: {
  range: ToCellsInput;
  taxoFieldValidationToTemplateValue: (
    address: string,
    value: string,
    taxoFieldValidation: TaxoFieldValidation,
  ) => T | null;
}) {
  const validations = await listTaxoFieldValidations(options.range);

  const withValues = await Excel.run(async (context) => {
    const withRange = validations.map((validation) => ({
      ...validation,
      range: toRange(context, validation.address).load({ text: true }),
    }));

    await context.sync();

    return withRange.map((validation) => {
      const value = options.taxoFieldValidationToTemplateValue(
        validation.address,
        // we use the text instead of value because
        // - it is always a string (which match taxo builder inputs)
        // - it is the formatted value (.e.g. date format applied)
        validation.range.text[0][0],
        validation.taxoValidation,
      );

      if (!value) return null;

      return [validation.taxoValidation.fieldId, value] as [string, T];
    });
  });

  return Object.fromEntries(withValues.filter(nonNullish));
}
