export type FieldAnythingValue = string;

export type FieldAnythingValueInput = string | FieldAnythingValue;

/**
 * Convert field anything value input to the value
 * that should be puts in the taxonomy template output.
 */
export function formatFieldAnythingValue(options: {
  /** Accepts `TaxonomyFieldAnything` */
  field: { type: 'anything' };
  input: FieldAnythingValueInput | undefined;
}): string {
  const { input } = options;

  return input ?? '';
}

/**
 * Convert field anything value input to the value
 * structured output object.
 */
export function decodeFieldAnythingValue(options: {
  /** Accepts `TaxonomyFieldAnything` */
  field: { type: 'anything' };
  input: FieldAnythingValueInput | undefined;
}): FieldAnythingValue | undefined {
  const { input } = options;

  return input;
}
