export const validateStringValues = (values: unknown[][]): string[][] => {
  if (values.every((col) => col.every((row) => typeof row === 'string'))) {
    return values as string[][];
  }

  throw new CustomFunctions.Error(
    CustomFunctions.ErrorCode.invalidValue,
    'Invalid values',
  );
};

export const validateNumberValues = (values: unknown[][]): number[][] => {
  if (values.every((col) => col.every((row) => typeof row === 'number'))) {
    return values as number[][];
  }

  throw new CustomFunctions.Error(
    CustomFunctions.ErrorCode.invalidValue,
    'Invalid values',
  );
};
