import { useTemplateOutput } from '@grasp-gg/lib-core-taxonomies';

import { getTemplateFieldPivots } from '@grasp-gg/extension-excel/services/api/taxonomies/use-template';
import { hasTaxoBuilderValues } from '@grasp-gg/extension-excel/services/api/taxonomies/use-template';
import { findTemplateGenerationValues } from '@grasp-gg/extension-excel/services/taxo/template-generation/find-template-generation-values';
import { useAuthStore } from '@grasp-gg/extension-excel/store/auth';
import { mapValues } from 'radash';

export async function taxoTemplate(options: {
  atAddress: string;
  templateId: string;
  encodedValues: string;
}) {
  const auth = useAuthStore();

  const organizationId = auth.user?.organizationId;

  if (!organizationId)
    throw new CustomFunctions.Error(
      CustomFunctions.ErrorCode.invalidValue,
      'Organization not found',
    );

  const template = await getTemplateFieldPivots(
    organizationId,
    options.templateId,
  );

  if (!template)
    throw new CustomFunctions.Error(
      CustomFunctions.ErrorCode.invalidValue,
      'Template not found',
    );

  const values = await findTemplateGenerationValues({
    atAddress: options.atAddress,
    template: template.template,
    formulaEncodedValues: options.encodedValues,
    useSameAxisValues: false,
    useFormulaEncodedValues: true,
    usePrecedentValues: true,
  });

  return useTemplateOutput({
    fields: template.fieldPivots,
    values: mapValues(hasTaxoBuilderValues(values), (value) => value?.value),
    useDependencyOutput: () => {
      console.warn('Sub-template is not supported yet');

      return '';
    },
  });
}
