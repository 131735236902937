import {
  type Guideline,
  isTaxonomyGuideline,
} from '@grasp-gg/lib-core-guidelines';

export default (guidelines: Guideline[]) =>
  guidelines.filter((guideline) => {
    if (isTaxonomyGuideline(guideline)) {
      return true;
    }

    if (
      (
        ['MaxBudget', 'MaxDailyBudget', 'BidValue'] as Guideline['type'][]
      ).includes(guideline.type)
    ) {
      return true;
    }

    return false;
  });
