/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  format as formatDate,
  formatISO,
  isValid as isValidDate,
  parse as parseFormatted,
  parseISO,
} from 'date-fns';

import { quasarFormatToUnicode } from '@grasp-gg/lib-core-helpers';

export type FieldDateValue = {
  /** ISO encoded date */
  raw: string;
  /** ISO encoded date */
  enriched: string;
};

export type FieldDateValueInput = string | Date | Partial<FieldDateValue>;

/**
 * Convert field date value input to the value
 * that should be puts in the taxonomy template output.
 */
export function formatFieldDateValue(options: {
  /** Accepts `TaxonomyFieldDate` */
  field: { type: 'date'; parameters: { format: string } };
  input: FieldDateValueInput | undefined;
}): string {
  const { field, input } = options;
  // Taxonomy date fields use quasar formatting instead of standard unicode
  const format = quasarFormatToUnicode(field.parameters.format);
  const value = asDate(input, format);

  return value ? formatDate(value, format) : '';
}

/**
 * Convert field date value input to the value
 * structured output object.
 */
export function decodeFieldDateValue(options: {
  /** Accepts `TaxonomyFieldDate` */
  field: { parameters: { format: string } };
  input: FieldDateValueInput | undefined;
}): FieldDateValue | undefined {
  const { field, input } = options;
  // Taxonomy date fields use quasar formatting instead of standard unicode
  const format = quasarFormatToUnicode(field.parameters.format);
  const value = asDate(input, format);

  if (!value) return undefined;

  return {
    raw: formatDate(value, format),
    enriched: formatISO(value),
  };
}

// eslint-disable-next-line complexity
function asDate(
  input: FieldDateValueInput | undefined,
  dateFormat: string,
): Date | undefined {
  if (input === undefined) return undefined;
  else if (input instanceof Date) return input;
  else if (typeof input === 'string') {
    let value = parseFormatted(input, dateFormat, new Date());

    if (isValidDate(value)) return value;

    value = parseISO(input);

    if (isValidDate(value)) return value;
  } else if (typeof input === 'object' && input.enriched) {
    const value = parseISO(input.enriched);

    if (isValidDate(value)) return value;
  }

  return undefined;
}
