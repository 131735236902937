import { assert } from '@vueuse/core';

export const extendRight = (range: Excel.Range, count: number) =>
  extend(range, { right: count });
export const extendBottom = (range: Excel.Range, count: number) =>
  extend(range, { bottom: count });

export async function extend(
  range: Excel.Range,
  indexes: {
    right?: number;
    left?: number;
    top?: number;
    bottom?: number;
  },
) {
  const { right = 0, left = 0, bottom = 0, top = 0 } = indexes;

  const tableRange = range.load({
    rowCount: true,
    rowIndex: true,
    columnCount: true,
    columnIndex: true,
  });

  await range.context.sync();

  return range.worksheet.getRangeByIndexes(
    Math.max(tableRange.rowIndex - top, 0),
    Math.max(tableRange.columnIndex - left, 0),
    Math.max(tableRange.rowCount + bottom + top, 0),
    Math.max(tableRange.columnCount + right + left, 0),
  );
}

export function split(range: Excel.Range) {
  const { cellCount } = range.toJSON();

  if (cellCount && cellCount === 1) return [range];

  const rows = splitRows(range, range.rowCount);

  return rows.flatMap((row) => splitColumns(row, range.columnCount));
}

export function splitRows(range: Excel.Range, rowCount: number) {
  return Array.from({ length: rowCount }).map((_, index) =>
    range.getRow(index),
  );
}

export function splitColumns(range: Excel.Range, columnCount: number) {
  return Array.from({ length: columnCount }).map((_, index) =>
    range.getColumn(index),
  );
}

export function fill(
  range: Excel.Range,
  withValue: (row: number, column: number) => string,
) {
  return Array.from({ length: range.rowCount }).map((_, row) =>
    Array.from({ length: range.columnCount }).map((__, column) =>
      withValue(row, column),
    ),
  );
}

export function fillWith(range: Excel.Range, withValue: string) {
  return fill(range, () => withValue);
}

export type ToCellsInput =
  | Excel.WorkbookRangeAreas
  | Excel.RangeAreas
  | Excel.Range;
export async function toCells(range: ToCellsInput) {
  if (range instanceof Excel.WorkbookRangeAreas)
    return workbookRangeToCells(range);
  if (range instanceof Excel.RangeAreas)
    return worksheetRangeAreasToCells(range);

  range.load({
    columnCount: true,
    rowCount: true,
    cellCount: true,
  });

  await range.context.sync();

  assert(
    range.cellCount < 1_000,
    `Converting a big range (${range.cellCount}) to cells can impact performance... Please make sure the range is smaller than 1000 cells.`,
  );

  const res = split(range);

  return res;
}

async function workbookRangeToCells(range: Excel.WorkbookRangeAreas) {
  // we load the address to force excel to load collection items
  range.areas.load({ address: true });

  await range.context.sync();

  if (range.areas.isNullObject) return [];

  const cells = [] as Excel.Range[];

  for (const worksheetRangeAreas of range.areas.items) {
    const worksheetCells =
      await worksheetRangeAreasToCells(worksheetRangeAreas);

    cells.push(...worksheetCells);
  }

  return cells;
}

async function worksheetRangeAreasToCells(range: Excel.RangeAreas) {
  const areas = range.areas.load({
    address: true,
    cellCount: true,
    rowCount: true,
    columnCount: true,
  });

  await range.context.sync();

  if (areas.isNullObject) return [];

  return areas.items.flatMap(split);
}
