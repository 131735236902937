import type { TaxonomyFieldDate } from '@grasp-gg/lib-core-taxonomies';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function asDataValidation(_field: TaxonomyFieldDate) {
  return {
    date: {
      operator: 'NotEqualTo',
      formula1: '01/01/1900',
    },
  } satisfies Excel.DataValidationRule;
}

export function encodeParameters(field: TaxonomyFieldDate) {
  return [field.parameters.format];
}

export function asFormattedValue(field: TaxonomyFieldDate) {
  return field.parameters.format;
}
