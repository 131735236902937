const TABLE_NAME = '_gg_txo_val_cache';

export function parameters(fieldId: string) {
  return `INDIRECT("${TABLE_NAME}[${fieldId}]")`;
}

export function parametersAt(fieldId: string, index: number) {
  return `INDEX(${parameters(fieldId)},${index})`;
}

export function self() {
  return 'INDIRECT("RC", FALSE)';
}
