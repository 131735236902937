import type { TemplatesFieldsPivotFromDataBase } from '../database';
import type { TaxonomiesFieldsPivot } from '../taxonomies';

export const pivotDbToApi = (
  fields: TemplatesFieldsPivotFromDataBase[],
  options?: {
    convertSubTemplates?: boolean;
  },
): TaxonomiesFieldsPivot[] => {
  const data: TaxonomiesFieldsPivot[] = [];

  const toPivot = (field: TemplatesFieldsPivotFromDataBase) =>
    ({
      id: field.id,
      templateId: field.templateId,
      field: {
        id: field.field.id,
        label: field.field.label,
        type: field.field.type,
        parameters: field.field.parameters,
        description: field.field.description,
        template:
          field.field.type === 'template' ? field.field.template : undefined,
        templateId:
          field.field.type === 'template' ? field.field.templateId : undefined,
      },
      position: field.position,
      displayPosition: field.displayPosition ?? field.position,
      required: field.required,
    }) as TaxonomiesFieldsPivot;

  const pushField = (field: TemplatesFieldsPivotFromDataBase) => {
    if (
      options?.convertSubTemplates &&
      field.field.type === 'template' &&
      field.field.template
    ) {
      field.field.template.fields.forEach((subfield) => {
        pushField(subfield);
      });
    } else {
      data.push(toPivot(field));
    }
  };

  fields.forEach((field) => {
    pushField(field);
  });

  return data;
};
