import escapeRegExp from 'lodash/escapeRegExp';

import type { StandardFieldText } from '../../fields';

// eslint-disable-next-line complexity
export default (field: StandardFieldText): string => {
  let options = '';

  if (
    !field.include ||
    !('lowercase' in field.include) ||
    field.include.lowercase
  ) {
    options += 'a-z';
  }
  if (
    !field.include ||
    !('uppercase' in field.include) ||
    field.include.uppercase
  ) {
    options += 'A-Z';
  }
  if (!field.include || !('digits' in field.include) || field.include.digits) {
    options += '0-9';
  }
  if (field.include?.spaces) {
    options += ' ';
  }
  if (field.include?.characters) {
    options += escapeRegExp(field.include.characters).replace('-', '\\-');
  }

  return `[${options}]`;
};
