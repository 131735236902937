import { getCurrentHub } from '@sentry/core';
import type { Span } from '@sentry/types';

export default <TParameters extends unknown[], TResult>(
  op: string,
  wrapped: (...parameters: TParameters) => Promise<TResult>,
  transaction?: Parameters<Span['startChild']>[0],
): ((...parameters: TParameters) => Promise<TResult>) =>
  (...parameters) => {
    const hub = getCurrentHub();
    const span = hub
      .getScope()
      ?.getTransaction()
      ?.startChild({
        op,
        ...transaction,
      });

    hub.configureScope((scope) => scope?.setSpan(span));

    return wrapped(...parameters).finally(() => span?.finish());
  };
