import type { Pinia } from 'pinia';

import type { Guideline } from '@grasp-gg/lib-core-guidelines';

import { useAuthStore } from '../../../store/auth';
import getGuideline from '../../api/guidelines/get-guideline';

export default async (
  guidelineId: string,
  pinia?: Pinia,
): Promise<Guideline | undefined> => {
  const authStore = useAuthStore(pinia);
  const organizationId = authStore.user?.organizationId;

  if (!organizationId) {
    throw new CustomFunctions.Error(
      CustomFunctions.ErrorCode.notAvailable,
      'Missing Organization ID',
    );
  }

  const expire = Math.floor(Date.now() / (1000 * 60 * 2));

  const cacheId = `${organizationId}_${guidelineId}_${expire}`;

  const guidelineFromCache =
    (await OfficeRuntime.storage.getItem(cacheId)) ?? undefined;

  if (guidelineFromCache) {
    return JSON.parse(guidelineFromCache);
  }

  const guidelineResult = await getGuideline(
    organizationId,
    guidelineId,
    true,
    pinia,
  );

  if (!guidelineResult?.success) {
    throw new CustomFunctions.Error(
      CustomFunctions.ErrorCode.notAvailable,
      'Fail to fetch guideline',
    );
  }

  await OfficeRuntime.storage.setItem(
    cacheId,
    JSON.stringify(guidelineResult.guideline),
  );

  return guidelineResult.guideline;
};
