import type { Pinia } from 'pinia';

import type { TemplatesFieldsPivotFromDataBase } from '@grasp-gg/lib-core-taxonomies';

import type { Template } from '@grasp-gg/api-taxonomies';

import { useAuthStore } from '@grasp-gg/extension-excel/store/auth';

import getTemplate from '../../api/taxonomies/get-template';

export default async (
  templateId: string,
  pinia?: Pinia,
): Promise<
  | (Omit<Template, 'fields'> & { fields: TemplatesFieldsPivotFromDataBase[] })
  | undefined
> => {
  const authStore = useAuthStore(pinia);
  const organizationId = authStore.user?.organizationId;

  if (!organizationId) {
    throw new CustomFunctions.Error(
      CustomFunctions.ErrorCode.notAvailable,
      'Missing organizationId',
    );
  }

  const minutes = Date.now() / (1000 * 60);

  const cacheId = `${organizationId}_${templateId}_${minutes}`;

  const templateFromCache =
    (await OfficeRuntime.storage.getItem(cacheId)) ?? undefined;

  if (templateFromCache) {
    return JSON.parse(templateFromCache);
  }

  const templateResult = await getTemplate(organizationId, templateId);

  if (!templateResult?.success) {
    throw new Error('Fail to fetch template');
  }

  await OfficeRuntime.storage.setItem(
    cacheId,
    JSON.stringify(templateResult.template),
  );

  return templateResult.template;
};
