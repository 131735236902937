import type { Pinia } from 'pinia';

import type { GetTemplateResponse } from '@grasp-gg/api-taxonomies';

import { useApiStore } from '../../../store/api';

export default (organizationId: string, templateId: string, pinia?: Pinia) => {
  const apiStore = useApiStore(pinia);

  return apiStore.requestApiTaxonomies<GetTemplateResponse>(
    `/organizations/${
      organizationId
    }/templates/${templateId}?convertSubTemplates=true&convertChanges=true`,
  );
};
