import type { Pinia } from 'pinia';

import type { ListTemplatesResponse } from '@grasp-gg/api-taxonomies';

import { useApiStore } from '../../../store/api';

export default (
  organizationId: string,
  includeShared: boolean,
  convertChanges: boolean,
  convertSubTemplates: boolean,
  templateIds: string[],
  addExternalDependencies: boolean,
  pinia?: Pinia,
) => {
  const params = new URLSearchParams({
    includeShared: includeShared?.toString(),
    convertChanges: convertChanges?.toString(),
    convertSubTemplates: convertSubTemplates?.toString(),
    templateIds: templateIds.join(','),
    addExternalDependencies: addExternalDependencies?.toString(),
  });

  return useApiStore(pinia).requestApiTaxonomies<ListTemplatesResponse>(
    `/organizations/${organizationId}/templates?${params.toString()}`,
  );
};
