import type { Pinia } from 'pinia';

import type { GetFieldSuccessResponse } from '@grasp-gg/api-taxonomies';

import getField from '@grasp-gg/extension-excel/services/api/taxonomies/get-field';
import { useAuthStore } from '@grasp-gg/extension-excel/store/auth';

export default async (
  fieldId: string,
  pinia?: Pinia,
): Promise<GetFieldSuccessResponse['field'] | undefined> => {
  const authStore = useAuthStore(pinia);
  const organizationId = authStore.user?.organizationId;

  if (!organizationId) {
    console.error('Missing organizationId', organizationId);

    throw new CustomFunctions.Error(
      CustomFunctions.ErrorCode.notAvailable,
      'Missing organizationId',
    );
  }

  const minutes = Date.now() / (1000 * 60);

  const cacheId = `${organizationId}_${fieldId}_${minutes}`;

  const fieldFromCache =
    (await OfficeRuntime.storage.getItem(cacheId)) ?? undefined;

  if (fieldFromCache) {
    return JSON.parse(fieldFromCache);
  }

  const fieldResult = await getField(organizationId, fieldId);

  console.log(fieldResult);

  if (!fieldResult?.success) {
    throw new Error('Fail to fetch field');
  }

  return undefined;
};
