import convertTaxonomyToStandardCheck from './taxonomy-to-standard-factory';

import type { StandardField, TaxonomiesFieldsPivot } from '..';

export default (
  pivots: TaxonomiesFieldsPivot[],
  realCheck = false,
): StandardField[] => {
  return pivots.map(convertTaxonomyToStandardCheck(realCheck));
};
