import {
  type FieldAnythingValue,
  type FieldAnythingValueInput,
  decodeFieldAnythingValue,
  formatFieldAnythingValue,
} from './anything.value';
import {
  type FieldCheckboxValue,
  type FieldCheckboxValueInput,
  decodeFieldCheckboxValue,
  formatFieldCheckboxValue,
} from './checkbox.value';
import {
  type FieldChoiceValue,
  type FieldChoiceValueInput,
  decodeFieldChoiceValue,
  formatFieldChoiceValue,
} from './choice.value';
import {
  type FieldDateValue,
  type FieldDateValueInput,
  decodeFieldDateValue,
  formatFieldDateValue,
} from './date.value';
import {
  type FieldNumberValue,
  type FieldNumberValueInput,
  decodeFieldNumberValue,
  formatFieldNumberValue,
} from './number.value';
import type {
  TaxonomyField,
  TaxonomyFieldFixed,
  TaxonomyFieldTemplate,
} from './taxonomies';
import {
  type FieldTextValue,
  type FieldTextValueInput,
  decodeFieldTextValue,
  formatFieldTextValue,
} from './text.value';
import type { FieldType } from './types';
import {
  type FieldUrlValue,
  type FieldUrlValueInput,
  decodeFieldUrlValue,
  formatFieldUrlValue,
} from './url.value';

export * from './url.value';
export * from './choice.value';
export * from './anything.value';
export * from './checkbox.value';
export * from './date.value';
export * from './number.value';
export * from './text.value';

export type AnyFieldValue =
  | FieldAnythingValue
  | FieldUrlValue
  | FieldChoiceValue
  | FieldCheckboxValue
  | FieldDateValue
  | FieldNumberValue
  | FieldTextValue;

export type AnyFieldValueInput =
  | FieldAnythingValueInput
  | FieldUrlValueInput
  | FieldChoiceValueInput
  | FieldCheckboxValueInput
  | FieldDateValueInput
  | FieldNumberValueInput
  | FieldTextValueInput;

export function formatAnyFieldValue(
  field: Exclude<TaxonomyField, TaxonomyFieldFixed | TaxonomyFieldTemplate>,
  value: AnyFieldValueInput | undefined,
) {
  type AnyFieldType = Exclude<FieldType, 'fixed' | 'template'>;
  type Formatter = (options: {
    field: TaxonomyField;
    input: AnyFieldValueInput;
  }) => string;

  const mapping = {
    date: formatFieldDateValue as Formatter,
    text: formatFieldTextValue as Formatter,
    number: formatFieldNumberValue as Formatter,
    choice: formatFieldChoiceValue as Formatter,
    checkbox: formatFieldCheckboxValue as Formatter,
    url: formatFieldUrlValue as Formatter,
    anything: formatFieldAnythingValue as Formatter,
  } satisfies Record<AnyFieldType, Formatter>;

  if (!value) return '';

  return mapping[field.type]({
    field,
    input: value,
  });
}

export function decodeAnyFieldValue(
  field: Exclude<TaxonomyField, TaxonomyFieldFixed | TaxonomyFieldTemplate>,
  value: AnyFieldValueInput | undefined,
) {
  type AnyFieldType = Exclude<FieldType, 'fixed' | 'template'>;
  type Formatter = (options: {
    field: TaxonomyField;
    input: AnyFieldValueInput;
  }) => AnyFieldValue;

  const mapping = {
    date: decodeFieldDateValue as Formatter,
    text: decodeFieldTextValue as Formatter,
    number: decodeFieldNumberValue as Formatter,
    choice: decodeFieldChoiceValue as Formatter,
    checkbox: decodeFieldCheckboxValue as Formatter,
    url: decodeFieldUrlValue as Formatter,
    anything: decodeFieldAnythingValue as Formatter,
  } satisfies Record<AnyFieldType, Formatter>;

  if (!value) return undefined;

  return mapping[field.type]({
    field,
    input: value,
  });
}
