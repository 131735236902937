import type { TaxonomyFieldAnything } from '@grasp-gg/lib-core-taxonomies';

import { parametersAt, self } from './utils';

export function asDataValidation(field: TaxonomyFieldAnything) {
  return {
    custom: {
      formula: `=AND(
        LEN(${self()})>=${parametersAt(field.id, 1)},
        LEN(${self()})<=${parametersAt(field.id, 2)},
        AND(${/* Check there is no #VALUE! (meaning invalid character) */ ''}
          NOT(${/* We want them to NOT be in the excluded list */ ''}
            ISNUMBER(${/* 1 if char is inside, #VALUE! if not */ ''}
              FIND(${/* For each character, find if it is in the allowed list */ ''}
                MID(${/* Extract all string characters */ ''}
                  ${parametersAt(field.id, 3)},
                  SEQUENCE(1, LEN(${parametersAt(field.id, 3)}), 1, 1),${/* Generates a list of character index for each char */ ''}
                  1
                ),
                ${self()}
              )
            )
          )
        )
      )`,
    },
  } satisfies Excel.DataValidationRule;
}

export function encodeParameters(field: TaxonomyFieldAnything) {
  return [field.parameters.min, field.parameters.max, field.parameters.exclude];
}
