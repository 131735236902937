import type { TaxonomyFieldNumber } from '@grasp-gg/lib-core-taxonomies';

import { parametersAt } from './utils';

export function asDataValidation(field: TaxonomyFieldNumber) {
  return {
    wholeNumber: {
      operator: 'Between',
      formula1: `=${parametersAt(field.id, 1)}`,
      formula2: `=${parametersAt(field.id, 2)}`,
    },
  } satisfies Excel.DataValidationRule;
}

export function encodeParameters(field: TaxonomyFieldNumber) {
  // /!\ These are min & max number of digits of the value
  const { min, max } = field.parameters;

  return [
    // Minimum value with `min` digits (e.g. min=2 => 10, min=3 => 100)
    min === 1 ? 0 : Math.pow(10, min - 1),
    // Maximum value with `max` digits (e.g. max=2 => 99, max=3 => 999)
    Math.pow(10, max) - 1,
  ];
}
