/**
 * Convert quasar date format to standard unicode tokens
 *
 * This function converts a Quasar date format (Moment's one) to a standard unicode tokens format.
 * It comes from issue #2991 on date-fns repository.
 *
 * Quasar formating: https://quasar.dev/quasar-utils/date-utils/#format-for-display
 * Unicode tokens: https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
 * Moment.js to Unicode tokens: https://github.com/date-fns/date-fns/issues/2991
 */
export function quasarFormatToUnicode(quasar: string) {
  return quasar
    .replace(/\[(.+)]/g, "'$1'")
    .replace(/LT/g, 'p')
    .replace(/LTS/g, 'pp')
    .replace(/LLLL|llll/g, 'PPPPp')
    .replace(/LLL|lll/g, 'PPp')
    .replace(/[Ll]/g, 'P')
    .replace(/Y/g, 'y')
    .replace(/g/g, 'Y')
    .replace(/G/g, 'R')
    .replace(/W/g, 'l')
    .replace(/E/g, 'i')
    .replace(/(D+|d+)o?/g, (inp: string) => {
      switch (inp) {
        case 'D': // 1 2 ... 30 31
        case 'Do': // 1st 2nd ... 30th 31st
        case 'DD': // 01 02 ... 30 31
          return inp.replace(/D/g, 'd');
        case 'DDD': // 1 2 ... 364 365
          return 'D';
        case 'DDDo': // 1st 2nd ... 364th 365th
          return 'Do';
        case 'DDDD': // 001 002 ... 364 365
          return 'DDD';

        case 'dd': // Su Mo ... Fr Sa
          return 'eeeeee';
        case 'd': // 0 1 ... 5 6
        case 'do': // 0th 1st ... 5th 6th
        case 'ddd': // Sun Mon ... Fri Sat
        case 'dddd': // Sunday Monday ... Friday Saturday
          return inp.replace(/d/g, 'e'); // will actually return value between 1 and 7 for the numerical results
      }

      return inp;
    })
    .replace(/a/g, "aaaaa'm'")
    .replace(/A/g, 'a')
    .replace(/T/g, "'T'")
    .replace(/X/g, 't')
    .replace(/x/g, 'T')
    .replace(/ZZ|zz/g, 'xx')
    .replace(/[Zz]/g, 'xxx');
}
