import { nonNullish } from '@grasp-gg/extension-excel/utils';
import {
  type ToCellsInput,
  toCells,
} from '@grasp-gg/extension-excel/utils/excel/range';

import { isTaxoFieldValidation } from './serialization';

export async function listTaxoFieldValidations(range: ToCellsInput) {
  // Always filter if possible to avoid performance issues
  // when the function is called on entire columns / rows etc...
  const cells = await toCells(
    'getSpecialCellsOrNullObject' in range
      ? range.getSpecialCellsOrNullObject(Excel.SpecialCellType.dataValidations)
      : range,
  );

  cells.forEach((cell) =>
    cell.load({
      address: true,
      dataValidation: {
        rule: true,
        type: true,
        prompt: true,
        errorAlert: true,
      },
    }),
  );

  await range.context.sync();

  return cells
    .map((cell) => {
      const taxoValidation = isTaxoFieldValidation(cell.dataValidation);

      if (!taxoValidation) return null;

      return {
        address: cell.address,
        taxoValidation,
      };
    })
    .filter(nonNullish);
}
