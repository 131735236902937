import escapeRegExp from 'lodash/escapeRegExp';

import type { StandardField } from '../../../fields';
import type { ValidRegex } from '../types';

export default (
  fields: StandardField[],
  regexes: ValidRegex[],
  matches: string[],
  numberOfValidFields: number,
): ValidRegex[] =>
  regexes.reduceRight(
    ({ newRegexes, numberOfValidFieldsUntilNow }, [regex, valid], index) => ({
      newRegexes: [
        [
          numberOfValidFieldsUntilNow >= numberOfValidFields
            ? // if the field is optional, so should be his fixed optimization
              `(${escapeRegExp(matches[index])})${fields[index].required ? '' : '?'}`
            : regex,
          valid,
        ] as ValidRegex,
        ...newRegexes,
      ] as ValidRegex[],
      numberOfValidFieldsUntilNow: matches[index]
        ? numberOfValidFieldsUntilNow +
          ((
            {
              choice: 3,
              fixed: 1,
            } as Record<StandardField['type'], number | undefined>
          )[fields[index]?.type] ?? 0)
        : numberOfValidFieldsUntilNow,
    }),
    {
      newRegexes: [] as ValidRegex[],
      numberOfValidFieldsUntilNow: 0,
    },
  ).newRegexes;
