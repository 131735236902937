import { addBreadcrumb, setContext } from '@sentry/core';
import type { Breadcrumb, Severity } from '@sentry/types';

export default <TInput extends unknown[], TResult>(
  message: string,
  callback: (...input: TInput) => Promise<TResult>,
) =>
  async (...input: TInput): Promise<TResult> => {
    try {
      setContext('Lambda Query', Object.assign({}, input));

      const result = await callback(...input);

      addBreadcrumb({
        message: `${message}-result`,
        data: result as Breadcrumb['data'],
      });

      return result;
    } catch (error) {
      addBreadcrumb({
        level: 'error' as Severity,
        message: `${message}-error`,
        data: error,
      });

      throw error;
    }
  };
