/* eslint-disable @typescript-eslint/no-use-before-define */
import type { FieldKeyValueItem } from './choice';

export type FieldCheckboxValue = {
  key: string;
  value: string;
};

export type FieldCheckboxValueInput = string | boolean | FieldCheckboxValue;

/**
 * Convert field checkbox value input to the value
 * that should be puts in the taxonomy template output.
 */
export function formatFieldCheckboxValue(options: {
  /** Accepts `TaxonomyFieldCheckbox` */
  field: { type: 'checkbox'; parameters: { keyValues: FieldKeyValueItem[] } };
  input: FieldCheckboxValueInput | undefined;
}): string {
  const { field, input } = options;

  const keyValue = asKeyValue(input, field.parameters.keyValues);

  return keyValue ? keyValue.value : '';
}

/**
 * Convert field checkbox value input to the value
 * structured output object.
 */
export function decodeFieldCheckboxValue(options: {
  /** Accepts `TaxonomyFieldCheckbox` */
  field: { type: 'checkbox'; parameters: { keyValues: FieldKeyValueItem[] } };
  input: FieldCheckboxValueInput | undefined;
}): FieldCheckboxValue | undefined {
  const { field, input } = options;

  const keyValue = asKeyValue(input, field.parameters.keyValues);

  if (!keyValue) return undefined;

  return {
    value: keyValue.value,
    key: keyValue.key,
  };
}

function asKeyValue(
  input: FieldCheckboxValueInput | undefined,
  keyValues: FieldKeyValueItem[],
): FieldKeyValueItem | undefined {
  if (typeof input === 'string') {
    return keyValues.find(({ value }) => value === input);
  } else if (typeof input === 'boolean') {
    return keyValues[input ? 0 : 1];
  } else if (typeof input === 'object' && input.key) {
    return keyValues.find(({ value }) => value === input.key);
  }

  return undefined;
}
