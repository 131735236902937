/* eslint-disable complexity */
import type { TaxonomyField } from '@grasp-gg/lib-core-taxonomies';

import * as anything from './validations/taxo-field-anything-validation';
import * as checkbox from './validations/taxo-field-checkbox-validation';
import * as choice from './validations/taxo-field-choice-validation';
import * as date from './validations/taxo-field-date-validation';
import * as fixed from './validations/taxo-field-fixed-validation';
import * as number from './validations/taxo-field-number-validation';
import * as text from './validations/taxo-field-text-validation';
import * as url from './validations/taxo-field-url-validation';

export interface TaxoFieldValidation {
  fieldId: string;
  /** Whether or not the user can input an invalid value */
  isInvalidValueAllowed: boolean;
  /** Whether or not the cell is formatting when the value is invalid */
  isInvalidValueHighlighted?: boolean;
}

export function isTaxoFieldValidation(
  dataValidation: Excel.Interfaces.DataValidationData,
): TaxoFieldValidation | null {
  try {
    const message = JSON.parse(dataValidation.prompt?.message ?? '');

    if (!message._gg_field_id) return null;

    const { showAlert, style } = dataValidation.errorAlert ?? {};
    const stopWhenInvalid = showAlert === true && style === 'Stop';

    return {
      fieldId: message._gg_field_id,
      isInvalidValueAllowed: !stopWhenInvalid,
      // The field cannot be inferred from data validation, we need to
      // load the conditional formatting applied to the cell to know the value
      isInvalidValueHighlighted: undefined,
    };
  } catch (e) {
    const isJsonParseError = e instanceof SyntaxError;

    if (!isJsonParseError) {
      // This is likely a bug in the code, we want to know about it
      console.warn(
        `Cannot parse data validation as taxo field validation: ${e}`,
        { error: e },
      );
    }

    return null;
  }
}

export function asTaxoFieldValidation(options: {
  field: TaxonomyField;
  invalidValueAllowed: boolean;
}): Excel.Interfaces.DataValidationData | null {
  const rule = buildValidationRule(options.field);

  if (!rule) return null;

  return {
    rule,
    prompt: {
      showPrompt: false,
      title: 'Managed by Grasp.gg, please do not modify.',
      message: JSON.stringify({ _gg_field_id: options.field.id }),
    },
    errorAlert: {
      showAlert: true,
      style: options.invalidValueAllowed ? 'Warning' : 'Stop',
      title: 'Invalid value',
      message: "The value doesn't match the Grasp taxonomy field constraints.",
    },
  } as Excel.Interfaces.DataValidationData;
}

function buildValidationRule(
  field: TaxonomyField,
): Excel.DataValidationRule | null {
  if (field.type === 'choice') return choice.asDataValidation(field);
  else if (field.type === 'number') return number.asDataValidation(field);
  else if (field.type === 'checkbox') return checkbox.asDataValidation(field);
  else if (field.type === 'date') return date.asDataValidation(field);
  else if (field.type === 'fixed') return fixed.asDataValidation(field);
  else if (field.type === 'text') return text.asDataValidation(field);
  else if (field.type === 'url') return url.asDataValidation(field);
  else if (field.type === 'anything') return anything.asDataValidation(field);

  // TODO: support more field types
  return null;
}

export function encodeTaxoFieldValidationParameters(field: TaxonomyField) {
  if (field.type === 'choice') return choice.encodeParameters(field);
  else if (field.type === 'number') return number.encodeParameters(field);
  else if (field.type === 'checkbox') return checkbox.encodeParameters(field);
  else if (field.type === 'date') return date.encodeParameters(field);
  else if (field.type === 'fixed') return fixed.encodeParameters(field);
  else if (field.type === 'text') return text.encodeParameters(field);
  else if (field.type === 'url') return url.encodeParameters(field);
  else if (field.type === 'anything') return anything.encodeParameters(field);

  return [];
}

export function asTaxoFieldFormattedValue(options: {
  field: TaxonomyField;
}) {
  const { field } = options;

  if (field.type === 'date') return date.asFormattedValue(field);

  return null;
}
