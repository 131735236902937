import type { TaxonomyFieldFromDatabase } from '../database';
import type { TaxonomiesFieldsPivot } from '../taxonomies';

export const fieldDbToApi = (
  fields: TaxonomyFieldFromDatabase[],
): TaxonomiesFieldsPivot[] =>
  fields.map(
    ({ label, type, parameters, id, description, templateId }) =>
      ({
        id: `${label}_${id}`,
        field: {
          id,
          label,
          type,
          parameters,
          description,
        },
        templateId,
        required: true,
      }) as TaxonomiesFieldsPivot,
  );
