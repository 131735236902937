import {
  type BidValueApiModel,
  checkValidityBidValue,
} from '@grasp-gg/lib-core-guidelines';

function validateBidValue(
  values: number[][],
  guideline: BidValueApiModel,
): boolean[][] {
  return values.map((col) =>
    col.map((row: number) =>
      checkValidityBidValue(row, guideline.min, guideline.max),
    ),
  );
}

export default validateBidValue;
