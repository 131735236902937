import type { Pinia } from 'pinia';

import getGuideline from './get-guideline-with-cache';
import {
  validateNumberValues,
  validateStringValues,
} from './validate-values-type';
import validateBidValue from './validators/validate-bid-value';
import validateMaxBudget from './validators/validate-max-budget';
import validateTaxonomy from './validators/validate-taxonomy';

// eslint-disable-next-line complexity
export default async (
  values: unknown[][],
  guidelineId: string,
  pinia?: Pinia,
): Promise<boolean[][]> => {
  const guideline = await getGuideline(guidelineId, pinia);

  if (!guideline) {
    throw new CustomFunctions.Error(
      CustomFunctions.ErrorCode.notAvailable,
      'Fail to fetch guideline',
    );
  }

  switch (guideline.type) {
    case 'TrackingTemplate':
    case 'NamingConvention':
    case 'UrlTemplate':
    case 'LabelTemplate':
    case 'ViewTagsTemplate':
    case 'ClickTagsTemplate':
    case 'UrlParametersTemplate':
      return validateTaxonomy(validateStringValues(values), guideline);
    case 'MaxBudget':
    case 'MaxDailyBudget':
      return validateMaxBudget(validateNumberValues(values), guideline);
    case 'BidValue':
      return validateBidValue(validateNumberValues(values), guideline);
    default:
      throw new CustomFunctions.Error(
        CustomFunctions.ErrorCode.notAvailable,
        'Guideline not supported',
      );
  }
};
