import { validateTaxonomyTemplate } from '@grasp-gg/lib-core-taxonomies';

import getTemplate from './get-template-with-cache';

export default async (
  values: string[][],
  taxonomyTemplateId: string,
): Promise<boolean[][]> => {
  const template = await getTemplate(taxonomyTemplateId);

  if (!template) {
    throw new CustomFunctions.Error(
      CustomFunctions.ErrorCode.notAvailable,
      'Fail to fetch template',
    );
  }

  return values.map((col): boolean[] =>
    col.map(
      (row: string) =>
        !validateTaxonomyTemplate(template, row, {
          instantFail: true,
        }).invalidFields.length,
    ),
  );
};
