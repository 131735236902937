import type { TaxonomyFieldFixed } from '@grasp-gg/lib-core-taxonomies';

import { parametersAt, self } from './utils';

export function asDataValidation(field: TaxonomyFieldFixed) {
  return {
    custom: {
      formula: `=EXACT(
        ${self()},
        ${parametersAt(field.id, 1)}
      )`,
    },
  } satisfies Excel.DataValidationRule;
}

export function encodeParameters(field: TaxonomyFieldFixed) {
  return [field.parameters.value];
}
