import {
  addBreadcrumb,
  captureEvent,
  captureException,
  captureMessage,
  getCurrentHub,
  getHubFromCarrier,
  makeMain,
  setTag,
  setTags,
  setUser,
  startTransaction,
} from '@sentry/core';

import helpers from './helpers';

const sentry = {
  makeMain,
  getHubFromCarrier,
  addBreadcrumb,
  startTransaction,
  getCurrentHub,
  setUser,
  setTag,
  setTags,
  captureException,
  captureEvent,
  captureMessage,
  ...helpers,
};

export default sentry;
