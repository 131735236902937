/* eslint-disable @typescript-eslint/no-use-before-define */

export type FieldUrlValue = string;

export type FieldUrlValueInput = string | FieldUrlValue;

/**
 * Convert field url value input to the value
 * that should be puts in the taxonomy template output.
 */
export function formatFieldUrlValue(options: {
  /** Accepts `TaxonomyFieldUrl` */
  field: { type: 'url' };
  input: FieldUrlValueInput | undefined;
}): string {
  const { input } = options;

  return asUrlValue(input) ?? '';
}

/**
 * Convert field url value input to the value
 * structured output object.
 */
export function decodeFieldUrlValue(options: {
  /** Accepts `TaxonomyFieldUrl` */
  field: { type: 'url' };
  input: FieldUrlValueInput | undefined;
}): FieldUrlValue | undefined {
  const { input } = options;

  return asUrlValue(input);
}

function asUrlValue(value: FieldUrlValueInput | undefined) {
  if (value === undefined) return undefined;

  if (!value.startsWith('http')) {
    return `https://${value}`;
  }

  return value;
}
